import {FMCAccordion} from '@ford/ford-design-system'
import React, {useEffect, useRef} from 'react'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {AMPLITUDE_SCREEN_NAME} from '../helperComponents/Analytics/AnalyticsConstants'

export default function Accordion(props) {
  const ref = useRef()

  useEffect(() => {
    new FMCAccordion(ref.current)
  }, [])

  function generateClassName() {
    let className = 'accordion js-fmc-accordion fmc-accordion fds-color__bg--light fmc-accordion--light'
    if (props.className) className += ` ${props.className}`
    return className
  }

  function onAccordionPanelClick(index) {
    analytics.fireLearnMoreAccordionTappedEvent(
      AMPLITUDE_SCREEN_NAME.LEARN_MORE,
      props.featureName,
      props.data[index].title,
    )
  }

  function generatePanelElement(index, title, content) {
    getMultiParagraphText(content)
    return (
      <div key={`panel-${index}`} className="accordion__panel fmc-accordion__panel">
        <button
          type="button"
          className="fmc-accordion__button"
          id={`panel-${index}-button`}
          data-testid={`panel-${index}-button`}
          aria-controls={`panel-${index}-content`}
          onClick={() => {
            onAccordionPanelClick(index)
          }}
        >
          <span className="fmc-accordion__button-title">{title}</span>
          <span className="fmc-accordion__button-expand fds-icon accordion__expand-icon" />
        </button>
        <div className="fmc-accordion__body">
          <div
            className="fmc-accordion__content"
            aria-labelledby={`panel-${index}-button`}
            id={`panel-${index}-content`}
            role="region"
            aria-hidden="false"
          >
            {content.includes('*') && paragraphs.map((paragraph, index) => (
              <div>
                <p key={`paragraph-${index}`} className="fmc-type--body1" style={{marginBottom: '1em'}}>
                  {paragraph.trim() === '' ? '\u00A0' : paragraph}
                </p>
              </div>
            ))}
            {!content.includes('*') &&
              <p className="fmc-type--body1">{content}</p>}
          </div>
        </div>
      </div>
    )
  }

  let paragraphs = '';
  function getMultiParagraphText(content) {
     if(content.includes('*')) paragraphs = content.split('*')
  }

  return (
    <div ref={ref} className={generateClassName()} data-testid="accordion">
      {props.data.map((item, i) => generatePanelElement(i, item.title, item.content))}
    </div>
  )
}
